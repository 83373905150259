











































































































































































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Ref } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import AttachSocialsModal from '@/components/modals/AttachSocialsModal.vue'
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import CardHint from '@/components/_uikit/CardHint.vue'
import ChangeEmailModal from '@/components/modals/profile/ChangeEmailModal.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UploadAvatar from '@/components/_uikit/controls/UploadAvatar.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import NewPasswordFormModal from '@/components/modals/profile/NewPasswordFormModal.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import VKIDProfile from '@/components/VKIDProfile.vue'
import AttachVKProblemModal from '@/components/modals/AttachVKProblemModal.vue'
import TelegramProfile from '@/components/TelegramProfile.vue'
import AttachTelegramProblemModal from '@/components/modals/AttachTelegramProblemModal.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import VKIDMixin from '@/mixins/VKIDMixin'
import TelegramMixin from '@/mixins/TelegramMixin'
// types
import {
  MediaResource,
  SocialResource,
  UserLargeResource,
  UserProfile,
} from '@/store/types'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import ProfileModule from '@/store/modules/profile'
import SystemModule from '@/store/modules/system'
// utils
// import { baseURL } from '@/utils/services/config'

@Component({
  components: {
    AttachSocialsModal,
    AttachVKProblemModal,
    AttachTelegramProblemModal,
    Autocomplete,
    CardHint,
    ChangeEmailModal,
    Confirmation,
    DateInput,
    NewPasswordFormModal,
    Select,
    TextInput,
    UploadAvatar,
    UserSocialCard,
    VKIDProfile,
    TelegramProfile,
    ValidationObserver,
    ValidationProvider,
  },
})

export default class ProfileInformationForm extends Mixins(NotifyMixin, PermissionsMixin, VKIDMixin, TelegramMixin) {
  @Ref() confirm!: Confirmation

  private showChangePassword = false
  private avatar = ''
  private socials: SocialResource[] = []
  private form: UserProfile = {
    avatarId: null,
    birthDate: '',
    email: '',
    isLocalTimezone: false,
    name: '',
    phone: '',
    socialIds: [],
    surname: '',
    timezoneIds: [],
  }

  private get cities () {
    return DictionaryModule.cities
  }

  private get timezones () {
    return DictionaryModule.timezones
  }

  private get information () {
    return ProfileModule.information
  }

  private get hasPassword() {
    return Boolean(this.information?.hasPassword)
  }

  private get hasEmail() {
    return Boolean(this.information?.email)
  }

  private get showHint () {
    return this.information && (!this.information.phone || this.information.socials.length < 2) || false
  }

  private get isManagerPage () {
    return SystemModule.interface === 'manager' && this.permissions.length
  }

  private currentEmail = ''
  private showAttachSocialsModal = false
  private showChangeEmailModal = false
  private visibleAttachVKProblemModal = false
  private visibleAttachTelegramProblemModal = false

  private mounted () {
    this.$bus.$on('setProfileForm', this.setForm)
    if (this.$route.query.error) {
      this.$router.replace({ query: {} })
        .catch(() => {return})
      setTimeout(this.openModalVKProblem, 5000)
    }
    DictionaryModule.fetchCities()

    DictionaryModule.fetchTimezones()

    if (!this.information) {
      ProfileModule.fetchInformation()
        .then((response: UserLargeResource) => {
          this.setForm(response)
          this.socials = response.socials
          this.showAttachSocialsModal = this.socials.length < ProfileModule.requiredSocials.length
        })
        .catch(this.notifyError)
    } else {
      this.setForm(this.information)
      this.socials = this.information.socials
      this.showAttachSocialsModal = this.socials.length < ProfileModule.requiredSocials.length
    }

    this.$bus.$on('telegramProblem', () => this.openModalTelegramProblem())
  }

  private destroyed() {
    this.$bus.$off('setProfileForm', this.setForm as any)
  }

  private setForm(data: UserLargeResource) {
    this.form = {
      ...this.form,
      avatarId: data.avatar ? data.avatar.id : null,
      birthDate: data.birthDate,
      cityId: data.city ? +data.city.value : undefined,
      email: data.email,
      isLocalTimezone: data.isLocalTimezone,
      name: data.name,
      phone: data.phone,
      socialIds: data.socials.map((social: SocialResource) => social.id),
      surname: data.surname,
      timezoneIds: data.timezones.map(timezone => timezone.value as number),
    }
    this.currentEmail = this.form.email
    this.avatar = data.avatar?.url || ''
    this.socials = [...data.socials]
  }

  private handleUploadAvatar (response: MediaResource) {
    this.avatar = response.url
    this.form.avatarId = response.id
  }

  private handleDeleteAvatar () {
    this.avatar = ''
    this.form.avatarId = null
  }

  private handleDeleteSocial (id: number) {
    this.socials = this.socials.filter(soc => soc.id !== id)
    this.form.socialIds = this.form.socialIds.filter(soc => soc !== id)
  }

  private toggleTheme(value: boolean) {
    value ? localStorage.setItem('themeDark', 'true') : localStorage.removeItem('themeDark')
  }

  private patchTelegram() {
    this.confirm.open(
      'Подключение Telegram',
      '<p><strong>Обрати внимание:</strong> для привязки телеграмма необходимо наличие <strong>имени пользователя</strong> (пример: @имя_пользователя) в Telegram.</p><p>Если у тебя его нет – установи, пожалуйста, и затем вернись к подключению Telegram на платформу.</p>',
      {
        buttonCancelText: 'Вернуться',
        buttonConfirmText: 'Продолжить',
      },
    )
      .then(this.handlePatchTelegram)
      .catch(() => {return})
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ProfileModule.updateInformation(this.form)
            .then(() => {
              AuthModule.fetchUser(true)
            })
            .then(() => {
              requestAnimationFrame(() => (form.reset()))
              this.notifySuccess('Данные сохранены')
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private pastePhone(phone: string) {
    if (!phone) return
    setTimeout(() => {
      this.form.phone = phone.replace(/^\+?7/m, '8')
    })
  }

  private handleChangeEmail(value: string) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // Если почта изменена на некорректную или на ту, что была перед исправлением (изначально), то ничего не делать
    if (re.test(value) && value !== this.currentEmail) {
      this.showChangeEmailModal = true
    }
  }

  private handleCloseChangeEmailModal(resetEmail: boolean) {
    this.showChangeEmailModal = false
    if (resetEmail)
      this.form.email = this.currentEmail
    else
      this.currentEmail = this.form.email
  }

  private openModalVKProblem() {
    this.visibleAttachVKProblemModal = true
  }

  private openModalTelegramProblem() {
    this.visibleAttachTelegramProblemModal = true
  }

  private accountIsLinked(socialType: string) {
    return this.socials.filter(social => social.type === socialType).length
  }

  private get socialVK() {
    return this.socials?.find(item => item.type === 'vkontakte')
  }

  private get socialTG() {
    return this.socials?.find(item => item.type === 'telegram')
  }
}
